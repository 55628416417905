import {YN, STATUS, CLIENT, ClientTimeUnit, PAGER} from '@constant';
import {createTimeoutPromise, dateFormat} from "@utils/utils";
import Api from '@api';
import {setSelectClientList, setSelectOwnerList, setSelectPalletList} from "../../../../../../api/common";

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [{
      "key": "PRE_ADVICE_ID",
      "label": "预通知",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入预通知",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CLIENT_ID",
      "label": "客户端",
      "type": "magnifier",
      "placeholder": "请选择客户端",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false,
      defaultUpper:true,
      "sheetName": "line"
    }, {
      "key": "LINE_ID",
      "label": "行号",
      "type": "numrange",
      "placeholder": "请选择行号",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "NOTES",
      "label": "注释",
      "type": "textarea",
      "placeholder": "请输入注释",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "col": 2
    }, {
      "key": "SKU_ID",
      "label": "SKU",
      "type": "magnifier",
      "placeholder": "请输入SKU",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "_DESCRIPTION_",
      "label": "描述",
      "type": "textarea",
      "placeholder": "描述",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": [
        "dataInfo"
      ],
      "col": "2",
    }, {
      "key": "QTY_DUE",
      "label": "应付数",
      "type": "numrange",
      "placeholder": "请选择应付数",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "QTY_DUE_TOLERANCE",
      "label": "超收百分比",
      "type": "numrange",
      "placeholder": "请选择超收百分比",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    },{
      "key": "QTY_DUE_TOLERANCE$QTY_DUE",
      "label": "应到最大数量",
      "type": "numrange",
      "placeholder": "应到最大数量",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    },{
      "key": "QTY_RECEIVED",
      "label": "接收数量 ",
      "type": "numrange",
      "placeholder": "请选择接收数量 ",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "QTY_ALLOCATED",
      "label": "分配数量",
      "type": "numrange",
      "placeholder": "请选择分配数量",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "UPI_QTY_DUE",
      "label": "Upi 数量限额 ",
      "type": "numrange",
      "placeholder": "请选择Upi 数量限额 ",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "TRACKING_LEVEL",
      "label": "跟踪级别 ",
      "type": "select",
      "placeholder": "请选择跟踪级别 ",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": []
    }, {
      "key": "CONFIG_ID",
      "label": "打包配置",
      "type": "select",
      "placeholder": "请选择打包配置",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": []
    }, {
      "key": "OWNER_ID",
      "label": "所有者",
      "type": "magnifier",
      "placeholder": "请选择所有者",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      // "options": async () => {
      //   let {status, data: {list}, message} = await setSelectOwnerList({
      //     WORKSTATION: {},
      //     ...defaultPaginationInfo
      //   });
      //
      //   if (Number(status) === 200) {
      //     return list.map(item=>{
      //       return{
      //         key:item.OWNER_ID,
      //         label:item.OWNER_ID
      //       }
      //     })
      //   }
      // },
    }, {
      "key": "TAG_ID",
      "label": "标记ID",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_LINE',
      "placeholder": "请输入标记ID",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "LOCK_CODE",
      "label": "锁定代码 ",
      "type": "magnifier",
      "placeholder": "请输入锁定代码 ",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CONDITION_ID",
      "label": "代码 ",
      "type": "magnifier",
      "placeholder": "请输入代码 ",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "ORIGIN_ID",
      "label": "来源",
      "type": "magnifier",
      "placeholder": "请输入来源",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "SPEC_CODE",
      "label": "规格代码 ",
      "type": "magnifier",
      "tableName": 'PRE_ADVICE_LINE',
      "placeholder": "请输入规格代码 ",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "SAMPLING_TYPE",
      "label": "取样类型 ",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入取样类型 ",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "MANUF_DSTAMP",
      "label": "生产日期 ",
      "type": "date",
      "placeholder": "请选择生产日期 ",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "MANUF_TIME",
      "label": "生产时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择生产时间",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "DISALLOW_MERGE_RULES",
      "label": "忽略合并规则",
      "type": "select",
      "placeholder": "请选择忽略合并规则",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "HOST_PRE_ADVICE_ID",
      "label": "主订单",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入主订单",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "HOST_LINE_ID",
      "label": "主订单行",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入主订单行",
      "tabGroup": "mqs_preadvline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "PRE_RECEIVED",
      "label": "预收货",
      "type": "select",
      "placeholder": "请选择预收货",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "TRAILER_ID",
      "label": "拖车ID",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入拖车ID",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CATCH_WEIGHT",
      "label": "重量",
      "type": "numrange",
      "placeholder": "请选择重量",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "PRE_RECEIPT_TAG_PRINTED",
      "label": "预接收标签打印",
      "type": "select",
      "placeholder": "请选择预接收标签打印",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "EXPIRY_DSTAMP",
      "label": "失效日期",
      "type": "date",
      "placeholder": "请选择失效日期",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "EXPIRY_TIME",
      "label": "失效时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择失效时间",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "BATCH_ID",
      "label": "批次",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入批次",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CREATED_BY",
      "label": "创建人",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入创建人 ",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CREATION_DATE",
      "label": "创建日期",
      "type": "date",
      "placeholder": "请选择创建日期",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CREATION_TIME",
      "label": "创建时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择创建时间",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "LAST_UPDATED_BY",
      "label": "最后更新人",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入最后更新人",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "LAST_UPDATE_DATE",
      "label": "最后更新日期",
      "type": "date",
      "placeholder": "请选择最后更新日期",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "LAST_UPDATE_TIME",
      "label": "最后更新时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择最后更新时间",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "PRODUCT_PRICE",
      "label": "价格",
      "type": "numrange",
      "placeholder": "请选择价格",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "PRODUCT_CURRENCY",
      "label": "币种",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入币种",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "SERIAL_VALID_MERGE",
      "label": "到期日连续需求",
      "type": "select",
      "placeholder": "请选择到期日连续需求",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "EXPECTED_NET_WEIGHT",
      "label": "预计净重",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入预计净重",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "EXPECTED_GROSS_WEIGHT",
      "label": "预计毛重",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入预计毛重",
      "tabGroup": "mqs_preadvline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_1",
      "label": "客户ID",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入客户ID",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_2",
      "label": "产地",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入产地",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_3",
      "label": "客户订单号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入客户订单号",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_4",
      "label": "ETQ-客户订单号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入ETQ-客户订单号",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_5",
      "label": "工厂化",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入工厂化",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_6",
      "label": "集装箱号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入集装箱号",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_7",
      "label": "虚拟库位号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入虚拟库位号",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_8",
      "label": "报检号",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入用户自定义类型 8 ",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    },  {
      "key": "USER_DEF_CHK_1",
      "label": "需分流",
      "type": "select",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请选择需分流",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_2",
      "label": "已消毒 ",
      "type": "select",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请选择已消毒",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_3",
      "label": "用户自定义的检查3 ",
      "type": "select",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入用户自定义的检查3 ",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_4",
      "label": "用户自定义的检查4 ",
      "type": "select",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入用户自定义的检查4 ",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_DATE_1",
      "label": "订单生产日期",
      "type": "date",
      "placeholder": "请选择用户自定义日期 1 ",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TIME_1",
      "label": "用户自定义时间 1 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 1 ",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_DATE_2",
      "label": "用户自定义日期 2 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 2 ",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TIME_2",
      "label": "用户自定义时间 2 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 2 ",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_DATE_3",
      "label": "用户自定义日期 3 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 3 ",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TIME_3",
      "label": "用户自定义时间 3 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 3 ",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_DATE_4",
      "label": "用户自定义日期 4 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 4 ",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TIME_4",
      "label": "用户自定义时间 4 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 4",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_NUM_1",
      "label": "行总重量",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入行总重量",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_NUM_2",
      "label": "用户自定义号码 2",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入用户自定义号码 2",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_NUM_3",
      "label": "用户自定义号码 3 ",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入用户自定义号码 3 ",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_NUM_4",
      "label": "单箱重量",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入单箱重量",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_NOTE_1",
      "label": "备注",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入备注",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_NOTE_2",
      "label": "用户自定义注释 2 ",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入用户自定义注释 2 ",
      "tabGroup": "user_def_pal_tab0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CE_CONSIGNMENT_ID",
      "label": "",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CE_COO",
      "label": "",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CE_INVOICE_NUMBER",
      "label": "",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CE_LINK",
      "label": "",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CE_UNDER_BOND",
      "label": "",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "LABOR_QTY_DUE",
      "label": "",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "LINE_ID_LINK",
      "label": "",
      "type": "numrange",
      "placeholder": "请选择",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "ORDER_ID_LINK",
      "label": "",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "PALLET_CONFIG",
      "label": "托盘类型 ",
      "type": "select",
      "placeholder": "请选择托盘类型 ",
      "showBlock": [],
      "checked": false,
      "sheetName": "line",
      "options": async () => {
        let {status, data: {list}, message} = await setSelectPalletList({
          PALLET_CONFIG: {},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.CONFIG_ID,
              label: item.CONFIG_ID
            }
          })
        }
      },
    }, {
      "key": "SAP_PLANT",
      "label": "SAP 工厂 ",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入SAP 工厂 ",
      "tabGroupName": "空",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "SAP_STORE_LOC",
      "label": "Sap库存位置 ",
      "type": "autoComplete",
      "tableName": "PRE_ADVICE_LINE",
      "placeholder": "请输入Sap库存位置 ",
      "tabGroupName": "空",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }]
  }
}