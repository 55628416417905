export const tab_en = {
  "THead": "THead",
  "mqs_preadvline_tab0": "mqs_preadvline_tab0",
  "mqs_preadvline_tab1": "mqs_preadvline_tab1",
  "user_def_pal_tab0": "user_def_pal_tab0"
};

export const tab_zh = {
  "THead": "表头",
  "mqs_preadvline_tab0": "综合",
  "mqs_preadvline_tab1": "杂项",
  "user_def_pal_tab0": "用户自定义的_设置1"
}

